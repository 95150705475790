a {
  &:focus {
    outline: none;
  }
}

a:not([href]) {
  color: var(--primary);
  text-decoration: none;

  &:hover() {
    text-decoration: none;
  }
}
