// $teste: var(--primary) !default;
// $secondary: var(--secondary) !default;
// $info: var(--info) !default;
// $warning: var(--warning) !default;
// $light: var(--light) !default;
// $dark: var(--dark) !default;


@import "~@ng-select/ng-select/themes/default.theme.css";

.disabled {
	color: black;
	cursor: not-allowed;
	pointer-events: none;
	text-decoration: none;
}

.loading-progress {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
}

.progress-div {
	z-index: 9998;
	position: fixed;
	width: 240px;
	height: 80px;
	top: 94%;
	left: 80%;
	background-color: #1e1e2d;
	border-radius: 20px 20px 0px 0px;
	animation: fade_in_show 0.5s;
}

@keyframes colorAnimation {
	from {
		background-position: 0 0, left;
	}

	to {
		background-position: 80px 0, left;
	}
}


@media (max-width: 600px) {
	.progress-div {
		left: 30% !important;
	}
}

.loading-indicator:before {
	content: "";
	display: block;
	position: fixed;
	z-index: 9997;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background-image: url('./assets/media/bg/loading_background.png');*/
	background-color: rgba(0, 0, 0, .5);
	opacity: 0.5;
	cursor: wait !important;
}

@keyframes fade_in_show {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.progress2 {
	padding: 6px;
	border-radius: 30px;
	background: rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
		0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
	height: 18px;
	border-radius: 5px;
	box-shadow: inset 0 2px 3px var(--primary),
		0 1px var(--primary);
}

.progress-infinite .progress-bar {
	margin-top: 20px;
	width: 200px;
	margin-left: 7%;
	background: linear-gradient(-45deg,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%) left/30px 30px repeat-x,
		linear-gradient(to right, var(--primary) 0%, var(--primary) 60%, var(--primary) 100%) left/var(--p, 100%) fixed,
		white;
	animation: colorAnimation 1s linear infinite;
}

.truncate {
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
}

@media only screen and (max-width: 1300px) {
	.hide-column {
		display: none;
	}
}

ngb-Alert {
	font-size: 10px;
}

/* in-flight clone */
.gu-mirror {
	position: fixed !important;
	margin: 0 !important;
	z-index: 9999 !important;
	opacity: 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	filter: alpha(opacity=80);
	pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
	left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
}

.pulse {
	animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 $warning;
	}

	70% {
		-webkit-box-shadow: 0 0 0 5px rgba(244, 157, 22, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 $warning;
		box-shadow: 0 0 0 0 $warning;
	}

	70% {
		-moz-box-shadow: 0 0 0 5px rgba(244, 157, 22, 0);
		box-shadow: 0 0 0 5px rgba(244, 157, 22, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
		box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
	}
}
