.text-primary {
    color: var(--primary) !important;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: var(--primary) !important;
    text-decoration: none !important;
    text-decoration-line: none !important;
    text-decoration-thickness: initial !important;
    text-decoration-style: initial !important;
    text-decoration-color: initial !important;
    background-color: var(--primary-lighten-6) !important;
}